// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": function componentNodeModulesGatsbyPluginOfflineAppShellJs() {
    return import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js"
    /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */
    );
  },
  "component---src-pages-404-js": function componentSrcPages404Js() {
    return import("/opt/build/repo/src/pages/404.js"
    /* webpackChunkName: "component---src-pages-404-js" */
    );
  },
  "component---src-pages-index-js": function componentSrcPagesIndexJs() {
    return import("/opt/build/repo/src/pages/index.js"
    /* webpackChunkName: "component---src-pages-index-js" */
    );
  },
  "component---src-pages-lp-cdf-index-js": function componentSrcPagesLpCdfIndexJs() {
    return import("/opt/build/repo/src/pages/lp-cdf/index.js"
    /* webpackChunkName: "component---src-pages-lp-cdf-index-js" */
    );
  }
};